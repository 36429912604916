.imgbg {
  height: 100vh;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/images/Navi-Logo-Inversion.svg"),
    linear-gradient(145.83deg, #0f0931 21.07%, #1e1057 60.91%, #632eff 144.5%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
