.dropdown {
  position: absolute;
  right: 0px;
  top: 50px;
  background-color: #622eff;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transform: translate(-30%, 110%);
  z-index: 200;
  width: 130px;
  cursor: pointer;
}

.dropdown::after {
  content: "";
  z-index: 300;
  position: absolute;
  top: 0;
  left: 50%;
  height: 1rem;
  width: 1rem;
  background-color: #622eff;
  transform: rotate(45deg) translateY(-70%);
  transition: all 100ms ease-in-out;
}
.dropdown li {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  text-decoration: none;
  list-style-type: none;
  padding: 1rem;
}

.dropdown li:hover {
}

.dropdow li:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
