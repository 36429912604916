.search input {
  padding: 10px 30px;
  background: #eef3f7;
  border-radius: 16px;
  width: 300px;
  outline: none;
  border: none;
  background-image: url("../../../../assets/images/Search.svg");
  background-position: 10px 12px;
  background-repeat: no-repeat;
}
@media (max-width: 1024px) {
  .search input {
    width: 300px;
  }
}

.images {
  mix-blend-mode: multiply;
}

@media (max-width: 500px) {
  .search input {
    width: 100%;
  }
}
.search input::placeholder {
  color: #bdbdbd;
}
