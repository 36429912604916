.search input {
  padding: 8px 30px;
  background: #f8fafc;
  border-radius: 28px;
  width: 456px;
  outline: none;
  background-image: url("../../../../assets/images/Search.svg");
  background-position: 10px 12px;
  background-repeat: no-repeat;
  border: 1px solid #e2e8f0;
}
@media (max-width: 1024px) {
  .search input {
    /* width: 300px; */
  }
}

.images {
  mix-blend-mode: multiply;
}

@media (max-width: 500px) {
  .search input {
    width: 100%;
  }
}
.search input::placeholder {
  color: #bdbdbd;
}

.files {
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  align-items: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.files::-webkit-scrollbar {
  display: none;
}
