.search input {
  padding: 10px 30px;
  background: #ebf7ff;
  border-radius: 20px;
  width: 400px;
  outline: none;
  border: none;
  background-image: url("../../assets/images/Search.svg");
  background-position: 10px 12px;
  background-repeat: no-repeat;
}
@media (max-width: 1024px) {
  .search input {
    width: 300px;
  }
}
.search input::placeholder {
  color: #bdbdbd;
}

.offer {
  color: #0f0931;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.checkmark {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  margin: 8px 0 !important;
}
.checkmark .text {
  display: flex;
  align-items: center;
}
.checkmark .text p {
  color: #0f0931;
  font-size: 14px;
  margin-right: 10px;
}
.checkmark .text span {
  color: #0f0931;
  font-size: 12px;
  color: #bdbdbd;
}

.checkmark .checkmarkDiv input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkmark .checkmarkDiv span {
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 1px solid #0f0931;
  border-radius: 3px;
}

.checkmark .checkmarkDiv span:after {
  content: "";
  position: absolute;
}

.checkmark:hover .checkmarkDiv input ~ span {
  background-color: #ccc;
}

.checkmark:hover .checkmarkDiv input:checked ~ span:after {
  display: block;
}

.checkmark .checkmarkDiv input:checked ~ span {
  background-color: #0f0931;
}

.checkmark .checkmarkDiv input:checked ~ span:after {
  left: 8px;
  top: 3px;
  width: 4px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
