.imgbg {
  height: 100vh;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    145.83deg,
    #0f0931 21.07%,
    #1e1057 60.91%,
    #632eff 144.5%
  );
}

.upload {
  background: #fafafa;
  border: 1px dashed #d8d8d8;
  box-sizing: border-box;
  border-radius: 8px;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

.upload .placeholder p {
  font-weight: 400;
  font-size: 17px;
  color: grey;
}
.upload .placeholder p span {
  color: #0778ff;
  font-weight: 700;
  cursor: pointer;
  font-size: 17px;
}
.upload .placeholder span {
  font-weight: 400;
  font-size: 13px;
  color: rgba(7, 28, 53, 0.51);
}
.files {
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  align-items: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.files::-webkit-scrollbar {
  display: none;
}

.files p {
  font-weight: 400;
  font-size: 17px;
  color: grey;
  margin-right: 16px;
  white-space: nowrap;
}

.files p:last-child {
  margin-right: 0;
}

.radioform {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.radio {
  background: #ffffff;
  border-radius: 24px;
  border: 1px solid var(--slate-3, #e2e8f0);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 300px;
  padding: 20px 10px;
}

.radio:hover {
  background: #f3f0ff;
  border: 1px solid #ac98fe;
}

.radiochecked {
  background: #f3f0ff;
  border: 1px solid #ac98fe;
}

/* @media (max-width: 500px) {
  .radio {
    border: 1px solid #632eff;
  }
  .radio label span {
    color: #632eff;
  }
} */

.search input {
  padding: 10px 30px;
  background: #f8fafc;
  border-radius: 28px;
  width: 456px;
  outline: none;
  background-image: url("../../assets/images/Search.svg");
  background-position: 10px 12px;
  background-repeat: no-repeat;
  border: 1px solid #e2e8f0;
}

@media (max-width: 500px) {
  .search input {
    width: 100%;
  }
}
.search input::placeholder {
  color: #bdbdbd;
}
