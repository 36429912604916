.search input {
  padding: 10px 30px;
  background: #eef3f7;
  border-radius: 16px;
  width: 400px;
  outline: none;
  border: none;
  background-image: url("../../../../assets/images/Search.svg");
  background-position: 10px 12px;
  background-repeat: no-repeat;
}
@media (max-width: 1024px) {
  .search input {
    width: 300px;
  }
}

.images {
  mix-blend-mode: multiply;
}

@media (max-width: 500px) {
  .search input {
    width: 100%;
  }
}
.search input::placeholder {
  color: #bdbdbd;
}

.files {
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  align-items: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.files::-webkit-scrollbar {
  display: none;
}

.pill {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  font-size: 12px;
  text-transform: capitalize;
  border-radius: 22px;
}

.pending {
  background: #fff8da;
  color: #d26a1a;
}

.arroved {
  background: #ecfdf5;
  color: #15803d;
}

.disapproved {
  background: #fef2f2;
  color: #b91c1c;
}

.unredeemed {
  background: #fff2d7;
  color: #a7580e;
}

.redeemed {
  background: #eefff4;
  color: #09702c;
}

.places {
  width: 100%;
  pointer-events: none;
}
