:root {
  --primary-purple: #0f0931;
}

.nav {
}

.hero {
  position: absolute;
  top: 35%;
  text-align: left;
  width: 100%;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* text-align: center; */
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: 0;
  mix-blend-mode: multiply;
  background: rgba(0, 0, 0, 0.58);
  /*mix-blend-mode: multiply; */
}

@media (max-width: 1120px) {
  .hero {
    padding: 3.5rem;
    width: 100%;
  }

  .image {
    height: 750px;
  }
}

@media (max-width: 570px) {
  .image {
    height: 550px;
  }
  .hero {
    padding: 2rem;
    top: 40%;
  }

  .hero svg {
    width: 19px;
  }
}

.mobileMenu {
  position: absolute;
  padding: 24px;
  background: #ebf7ff;
  width: 300px;
  z-index: 50;
  right: 0;
  top: 40px;
  filter: drop-shadow(0px 19px 16px rgba(85, 95, 103, 0.07));
  border-radius: 12px;
}

@media (max-width: 320px) {
  .mobileMenu {
    width: 100%;
    margin: 0;
  }
  .hero {
    top: 40%;
  }
}
.mobileMenu .links {
  display: flex;
  flex-direction: column;
}

.images {
  height: 200px;
  border-radius: 30px;
  width: 100%;
  object-fit: cover;
  mix-blend-mode: multiply;
}

.images:hover {
  filter: alpha(opacity=70);
  box-shadow: 0 0 0px #000000;
  transition: 0.4s ease-in-out;
  background: linear-gradient(
    180deg,
    rgba(13, 13, 13, 0.3) 0%,
    rgba(13, 13, 13, 0.7) 97.4%,
    rgba(13, 13, 13, 0.6) 100%
  );
}

.lost {
  position: relative;
  order: -9999;
}

.experience {
  display: grid;
  padding: 30px 60px;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  align-items: center;
}

@media (max-width: 320px) {
  .lost {
    order: 9999;
  }
  .experience {
    grid-template-columns: repeat(1, 1fr);
    padding: 40px 20px;
  }
}

/* styles for sections beneath hero */

.semiSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-block: 77px;
  gap: 19px;
}

.heading {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: var(--primary-purple);
  text-align: center;
  padding-inline: 2rem;
}

.subtext {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--primary-purple);
  text-align: center;
  max-width: 943px;
  padding-inline: 2rem;
}

.midSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-block: 75px;
  padding-inline: 25px;
  gap: 12px;
}

.midSection svg {
  width: 19px;
}

.midSubtext {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--primary-purple);
  text-align: center;
  max-width: 1011px;
}

.sideImageWrapper {
  background-color: var(--primary-purple);
  min-height: 550px;
}

.sideImageSection {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 3rem;
  height: 100%;
  padding-top: 70px;
}

.sideImageInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 20px;
}

.sideImageSection img {
  height: 350px;
  transform: rotate(-90deg);
  /* border-bottom-left-radius: 200px;
  border-top-left-radius: 150px; */
  margin-bottom: 13px;
  margin-left: 27px;
}

.doubleExp {
  display: none;
}

.sideImageText {
  margin-top: -75px;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.03em;
  color: #fff;
  padding-right: 3rem;
  padding-left: 2rem;
  text-align: center;
}

@media (min-width: 540px) {
  /* .sideImageSection img {
    height: 450px;
  } */
}

@media (min-width: 768px) {
  .sideImageWrapper {
    height: 400px;
    min-height: 350px;
  }

  .midSection {
    gap: 8px;
  }

  .midSection svg {
    width: 28px;
  }

  .heading {
    font-size: 44px;
    line-height: 56px;
  }

  .subtext {
    font-size: 20px;
    line-height: 28px;
  }

  .sideImageSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 53.55px;
    padding-top: 0px;
  }

  .sideImageSection img {
    margin-left: 47px;
    margin-bottom: -24px;
  }

  .sideImageText {
    font-size: 20px;
    max-width: 553px;
    padding-left: 1rem;
  }

  .sideImageText + a {
    padding-left: 0;
    display: inline-block;
  }

  .semiSection,
  .midSection {
    padding-block: 97px;
  }

  .midSubtext {
    font-size: 16px;
    line-height: 24px;
  }
}

@media (min-width: 1230px) {
  .doubleExp {
    margin-bottom: -230px !important;
  }
}

@media (min-width: 1080px) {
  .hero {
    top: 23%;
  }

  .sideImageSection {
    align-items: center;
    overflow: hidden;
  }

  .sideImageSection img {
    transform: rotate(0deg);
    margin-bottom: -44px;
    margin-left: 0;
  }

  .sideImg {
    display: none;
  }

  .sideImageInfo {
    margin-left: -150px;
  }

  .doubleExp {
    display: block;
    margin-left: 15vw !important;
    width: 80%;
  }

  .sideImageWrapper {
    height: 570px;
  }

  .midSubtext {
    font-size: 18px;
    line-height: 27px;
  }

  .midSection {
    gap: 6px;
  }

  .heading {
    font-size: 54px;
    line-height: 76px;
  }

  .subtext {
    font-size: 22px;
    line-height: 31px;
  }

  .semiSection {
    gap: 9px;
  }

  .sideImageSection {
    gap: 93.55px;
  }

  .sideImageSection img {
    height: auto;
  }

  .sideImageText {
    font-size: 22px;
  }
}

@media (min-width: 1240px) {
  .sideImageWrapper {
    height: 800px;
    overflow: hidden;
  }

  .midSection {
    gap: 0px;
  }

  .heading {
    font-size: 64px;
    line-height: 96px;
  }

  .subtext {
    font-size: 24px;
    line-height: 36px;
  }

  .sideImageText {
    font-size: 24px;
  }
}
