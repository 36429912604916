@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Raleway:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap");

:root {
  --swiper-theme-color: #007aff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Creato Display", "Roboto", sans-serif;
  letter-spacing: 0.015em;
}

@font-face {
  font-family: "Creato Display.otf";
  src: url(./assets/fonts/CreatoDisplay-Bold.otf);
  font-weight: 700;
}

@font-face {
  font-family: "Creato Display";
  src: url(./assets/fonts/CreatoDisplay-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: "Creato Display";
  src: url(./assets/fonts/CreatoDisplay-Regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: "Satoshi";
  src: url(./assets/fonts/Satoshi-Bold.otf);
  font-weight: 700;
}

@font-face {
  font-family: "Satoshi";
  src: url(./assets/fonts/Satoshi-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: "Satoshi";
  src: url(./assets/fonts/Satoshi-Regular.otf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url(./assets/fonts/Satoshi-Italic.otf);
  font-style: italic;
}

body,
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Creato Display", "Roboto", sans-serif;
  /* font-family: 'Poppins', sans-serif;
  font-weight: 400; */
  font-style: normal;
}

body {
  width: 100vw;
  height: 100vh;
}

.splide__arrows .splide-navigation-arrow {
  /* padding: 2rem; */
  background: #25aefc;
}

@media (min-width: 768px) {
  .splide__arrows .splide-navigation-arrow.city {
    display: none !important;
  }
}

.splide__arrow svg {
  width: auto;
  fill: white;
  height: auto;
  margin-top: 20px;
}

.splide__arrow {
  width: 67px;
  height: 67px;
}

.splide__arrow__img {
  width: 40px;
  height: 40px;
}

.splide__arrow--prev.splide-nav-prev svg {
  margin-right: 0.75em;
}

.splide__arrow--next.splide-nav-next svg {
  margin-left: 0.75em;
}

.splide__arrow--prev.splide-nav-prev {
  left: -2.5em;
}

.splide__arrow--prev.splide-img-prev {
  left: 0;
}

.splide__arrow--next.splide-nav-next {
  right: -2.5em;
}

.splide__arrow--next.splide-img-next {
  right: 0;
}

.splide__arrows .splide-navigation-arrow.splide-blog {
  top: 10.5em;
}

.splide__pagination.my-slide-pagination {
  bottom: 120px !important;
  left: -30px !important;
}

.splide__pagination.blog-pagination {
  bottom: -60px;
}

.splide__pagination__page.blog-pagination-page.is-active {
  background: #25aefc;
}

.splide__arrow.splide-navigation-arrow.city-collections {
  top: 6.75em;
}

@media screen and (max-width: 1080px) {
  .splide__arrow {
    width: 55px;
    height: 55px;
  }

  .splide__arrow--prev.splide-nav-prev {
    left: -1.75em;
  }

  .splide__arrow--next.splide-nav-next {
    right: -1.75em;
  }
}

@media screen and (max-width: 768px) {
  .splide__arrow {
    width: 47px;
    height: 47px;
  }

  .splide__arrow svg {
    width: 2em;
    margin-top: 17px;
  }
  .splide__arrows .splide-navigation-arrow.splide-blog {
    top: 9.5em;
  }

  .splide__arrow--prev.splide-nav-prev {
    left: -1.5em;
  }

  .splide__arrow--next.splide-nav-next {
    right: -1.5em;
  }

  .splide__pagination.blog-pagination {
    bottom: -25px;
  }
}

.react-datepicker-wrapper
  .react-datepicker__input-container
  .DatePicker_calender__nn6Uf {
  background: #f1f5f9 !important;
  border-radius: 26px !important;
  padding: 10px 30px !important;
  background-image: url("./assets/images/Calendar.svg") !important;
  background-position: 10px 14px !important;
  background-repeat: no-repeat !important;
}

.MuiFormControl-root {
  width: 100% !important;
}
.css-b62m3t-container .css-13cymwt-control {
  background: #ebf7ff;
}
/* .css-1kxonj9 {
  width: 100% !important;
  background: #f3f0ff !important;
  border-radius: 26px !important;
  overflow: hidden !important;
}
.css-1kxonj9:hover {
  border: 1px solid #ac98fe;
} */
.css-yk16xz-control {
  width: 100% !important;
  background: #f1f5f9 !important;
  border-radius: 20px !important;
}
.css-yk16xz-control:focus {
  background: #f3f0ff;
}

.css-yk16xz-control:focus-visible {
  border: 1px solid #ac98fe;
}

.css-1pahdxg-control {
  background: #f1f5f9 !important;
  border-radius: 20px !important;
}

.css-1pahdxg-control:hover {
  border: 1px solid #ac98fe !important;
  box-shadow: none;
}

.css-1pahdxg-control:focus {
  background: #f1f5f9 !important;
  box-shadow: none;
  border-radius: 20px !important;
}

.css-1pahdxg-control:focus-visible {
  border: 1px solid #ac98fe !important;
  box-shadow: none;
}

.css-2b097c-container .css-yk16xz-control {
  background: #ebf7ff;
}

.MuiOutlinedInput-adornedEnd {
  background: #f1f5f9;
  border: 1px solid #ac98fe;
  border-radius: 26px !important;
}

.MuiOutlinedInput-adornedEnd:hover {
  background: #f3f0ff !important;
  border: 1px solid #ac98fe;
}
.MuiInputBase-input {
  box-sizing: border-box !important;
}
.MuiInputBase-input::before {
  border-bottom: none !important;
}
.MuiInput-underline:after,
.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiInputBase-root:hover {
  border-bottom: none !important;
}

.MuiInputLabel-formControl {
  display: none !important;
}

.MuiInput-formControl {
  margin-top: 20px;
  border: 0.705598px solid #dfdddd;
  padding: 10px;
  width: 100px !important;
  background: #ebf7ff;
  border-radius: 26px;
  border-bottom: none !important;
}
.css-b62m3t-container {
  width: 100%;
}

.ant-picker {
  margin-top: 20px;
  padding: 10px;
  width: 140px !important;
  background: #f1f5f9;
  border-radius: 26px;
}

.ant-picker:hover {
  background: #f3f0ff;
  border: 1px solid #ac98fe;
}
.ant-picker-focused {
  border: 2px solid #dfdddd;
  box-shadow: none !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 0.705598px solid #dfdddd !important;
}

.steps div div div div[data-highlighted] {
  background: transparent;
  /* width: 30px;
  height: 30px; */
}

.steps div div div div span {
  text-align: left;
}

.steps div div div div span:nth-child(1) {
  font-family: "Creato Display", "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #0f0931;
}

.steps div div div div span:nth-child(2) {
  font-family: "Satoshi";
  font-weight: 400;
  font-style: normal;
  color: #878498;
  margin-top: 10px;
}

.css-jkojce {
  text-align: left !important;
}

.chakra-popover__popper {
  inset: unset !important;
  width: 100% !important;
}
.chakra-popover__content:focus-visible {
  outline: none !important;
  outline-offset: unset !important;
  box-shadow: 0px 4px 24px #d4deec !important;
}
.admin-nav-dropdown:hover {
  scale: 1.2;
  transition: ease 0.3s;
}

.button-animation-1 {
  transition: 0.2s ease-in;
}

.button-animation-1:hover {
  scale: 1.1;
}

.button-animation-1:active {
  scale: 0.9;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: #000;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 5px;
}

.tabs button[aria-selected="true"] {
  background: #252046 !important;
  color: #ffffff !important;
}

.tabs-light button[aria-selected="true"] {
  background: #ffffff !important;
  color: #0f0931 !important;
}

.timeinput select {
  width: 70px;
  padding: 10px;
  font-size: 14px;
  background: #f1f5f9;
  border-radius: 66px;
  -webkit-box-sizing: border-box;
  display: inline-block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAA3klEQVRIS+3VMU9CMRTF8d8zBL+aizoQFhx0kUk33RzdYMNFXUFnYeGrYYyaJiUxJHDLSxodbNKpfeffc9/pbaPyaCrr+3OA++z4rtT5Pg5GuMnCY9yWQEoBE1xhlUUP8YDrCBIB0vojLvGO0yz4hm4JJAKcYYoPHGOZAUdYoIMBXrc5iQAHeMlzviFygj7O8dkWEJU4XI8chALRhn9AVKHf70VRTHu4wFfbmKZLNKt50dLBnna0imcMd/2I0phWa3Y/D1e1Xa9BCZJG0VuQNpaWKMx72xS1Fl5/WN3BN+AgJhnZQlq4AAAAAElFTkSuQmCC");
  background-position: calc(100% - 0.5rem), 100% 0;
  background-size: 1.5em 1.5em;
  background-repeat: no-repeat;
}

.timeinput select:focus {
  background: #f3f0ff;
  outline: none !important;
}

.timeinput select:focus-visible {
  border: 1px solid #ac98fe !important;
  outline: none !important;
}
.timeinput input {
  font-family: "Satoshi";
  padding: 10px 20px;
  width: 100px;
  font-size: 14px;
  background: #f1f5f9;
  border-radius: 66px;
}

.timeinput input:focus {
  background: #f3f0ff;
}

.timeinput input:focus-visible {
  border: 1px solid #ac98fe !important;
  outline: none !important;
}

.timeinput input::-webkit-calendar-picker-indicator {
  display: none;
}

.css-1p3m7a8-multiValue {
  border-radius: 18px !important;
  padding: 0px 10px !important;
  align-items: center;
  gap: 10px;
}

.css-1p3m7a8-multiValue .css-12a83d4-MultiValueRemove {
  background: #64748b;
  width: 16px !important;
  height: 16px !important ;
  border-radius: 50% !important;
}
.css-1p3m7a8-multiValue .css-12a83d4-MultiValueRemove:hover {
  background: #64748b;
}

.css-1p3m7a8-multiValue .css-12a83d4-MultiValueRemove svg {
  fill: #ffffff !important;
}

.radio-label input {
  accent-color: #632eff;
}

.registeringAs {
  display: none !important;
}

.render-details ul,
.render-details .chakra-accordion__panel p {
  all: revert;
}
