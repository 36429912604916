.search input {
 padding: 8px 30px;
 background: #f8fafc;
 border-radius: 28px;
 width: 456px;
 outline: none;
 background-image: url("../../../../assets/images/Search.svg");
 background-position: 10px 12px;
 background-repeat: no-repeat;
 border: 1px solid #e2e8f0;
}

@media (max-width: 1024px) {
 .search input {
  /* width: 300px; */
 }
}

.images {
 mix-blend-mode: multiply;
}

@media (max-width: 500px) {
 .search input {
  width: 100%;
 }
}
.search input::placeholder {
 color: #bdbdbd;
}

.pill {
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 6px 10px;
 font-size: 12px;
 text-transform: capitalize;
 border-radius: 22px;
}

.pending {
 background: #fff8da;
 color: #d26a1a;
}

.approved {
 background: #ecfdf5;
 color: #15803d;
}

.disapproved {
 background: #fef2f2;
 color: #b91c1c;
}

.unredeemed {
 background: #fff2d7;
 color: #a7580e;
}

.redeemed {
 background: #eefff4;
 color: #09702c;
}
