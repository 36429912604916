.price {
}

.price:nth-child(1) div p {
  background: linear-gradient(180deg, #100a32 0%, #5d2cef 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.price:nth-child(2) div p {
  background: linear-gradient(180deg, #632ffe 33.61%, #ff8bff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.price:nth-child(3) div p {
  background: linear-gradient(180deg, #632ffe 44.79%, #25aefc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
