.tabs {
  flex: 1;
  margin-top: 30px;
}

.tabs ul {
  padding: 9px 10px;
  background: #f1f5f9;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  cursor: pointer;
  flex-direction: row;
}
.filter {
  display: none;
}

@media (max-width: 589px) {
  .tabs {
    margin-top: 10px;
  }

  .tabs ul {
    flex-direction: column;
    width: 100%;
  }
  .filter {
    display: block;
  }
}

.tabs ul li {
  text-decoration: none;
  list-style: none;
  padding: 5px 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0f0931;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  background: #25aefc !important;
  color: #ffffff !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
  border-radius: 40px;
  transition: 0.2s;
  transform: translateX(10px);
}
