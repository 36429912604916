.imgbg {
  height: 100vh;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    145.83deg,
    #0f0931 21.07%,
    #1e1057 60.91%,
    #632eff 144.5%
  );
}

.upload {
  background: #fafafa;
  border: 1px dashed #d8d8d8;
  box-sizing: border-box;
  border-radius: 8px;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

.upload .placeholder p {
  font-weight: 400;
  font-size: 17px;
  color: grey;
}
.upload .placeholder p span {
  color: #0778ff;
  font-weight: 700;
  cursor: pointer;
  font-size: 17px;
}
.upload .placeholder span {
  font-weight: 400;
  font-size: 13px;
  color: rgba(7, 28, 53, 0.51);
}
.files {
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  align-items: center;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.files::-webkit-scrollbar {
  display: none;
}

.files p {
  font-weight: 400;
  font-size: 17px;
  color: grey;
  margin-right: 16px;
  white-space: nowrap;
}

.files p:last-child {
  margin-right: 0;
}
